.icon {
  padding-right: .7em;
}
.button {
  background: var(--text);
  color: var(--text-light);
  border: 0;
  font-size: .8em;
  font-weight: 700;
  outline: none;
  margin: 5px;
  text-transform: uppercase;
}
.button:not(.secondary):not(.tertiary) {
  letter-spacing: 1px;
  font-family: 'Ironstrike-BoldItalic';
}
.button.secondary {
  color: var(--text);
  background: none;
}
.button.tertiary {
  background: none;
  color: var(--secondary);
}
.button:not(.sm) {
  padding: 8px 15px;
}
.button.sm {
  margin: 2px 5px;
  padding: 0px 15px;
}
.button:disabled {
  opacity: 0.5;
  cursor: default;
}
.button:hover:not(:disabled) {
  cursor: pointer;
}

.field-box {
  font-size: 12px;
}
.field-box:not(.inline) {
  padding: 15px 0 10px 0;
  border-bottom: 1px solid var(--secondary);
}
.field-box.inline {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 5px;
}
.field-box:last-of-type { border-bottom: 0; }

.field-label:not(.inline) {
  font-weight: 700;
  margin-right: 5px;
}
.field-label.inline {
  display: inline-block;
  width: 70px;
  font-weight: 500;
  margin: auto;
}
.field-label:not(.inline) {
  display: block;
  margin: 0 3px .3em 3px;
}

.field-input {
  background: var(--background-light);
  border: none;
  color: var(--text);
  font-size: 1em;
  outline: none;
  padding: .3em .45em;
  transition: background var(--default-transition);
}
.field-input:not(.inline) { width: 100%; }
.field-input.inline { flex: 1; margin: 0 10px 0 0; font-weight: 700; }

.field-input:disabled {
  background: var(--background);
  opacity: 1;
  -webkit-text-fill-color: var(--text);
}
.field-input[type='checkbox'] {
  flex: 0;
  zoom: 1.4;
}
.field-input[type='date'],
.field-input[type='time'],
.field-input[type='datetime-local'],
.field-input[type='month'],
.field-input[type='select'] {
  font-family: Helvetica;
  padding: .19em .4em;
}
.field-input[type='select'] {
  padding: .2em .1em;
  font-family: Helvetica;
}

.field-input:required + .field-label::after { content: '*'; }
.field-option {
  color: var(--text);
}
select.field-input:disabled,
.field-input[type='date']:disabled,
.field-input[type='datetime-local']:disabled,
.field-input[type='month']:disabled,
.field-input[type='time']:disabled,
.field-input[type='select']:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.field-input[type='select']:disabled {
  padding: .28em 1.59em .28em .44em;
}

.flex-col {
  display: flex;
  flex-flow: column nowrap;
}
.flex-row {
  display: flex;
  flex-flow: row nowrap;
}
.flex-row.spread { justify-content: space-between; }
.flex-row.start { justify-content: flex-start; }
.flex-row.center { justify-content: center; }

div .language-select {
  font-size: 14px;
}
div .language-select > span {
  font-size: 36px;
}

.grid-row, .grid-head {
  display: flex;
  flex-flow: row nowrap;
}
.grid-head:hover {
  cursor: pointer;
}
.grid-cell {
  padding: 8px;
}
.grid-head {
  background: var(--primary);
  font-size: 1.2em;
}


.backdrop {
  background: rgba(0,0,0,0);
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: background var(--default-transition);
  will-change: background;
  z-index: 10;
}
.backdrop.active {
  background: rgba(0,0,0,0.35);
  display: initial;
}
.modal {
  background: var(--background);
  margin: 10vh auto;
}

.swipepage-tabs {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid var(--background-dark);
}
.swipepage-tab {
  font-size: 11px;
  flex: 1;
  letter-spacing: 0.6px;
  padding: 15px 0 5px 0;
  text-align: center;
  text-transform: uppercase;
  -webkit-text-stroke: 0.8px var(--text);
}
.swipepage-progressbar {
  position: relative;
}
.swipepage-tabs:not(.full-width),
.swipepage-progressbar:not(.full-width) {
  margin: 0 20px;
}
.swipepage-progress {
  background: var(--primary-light);
  height: 100%;
  position: absolute;
  will-change: transform;
}
.swipepage-progress.transition {
  transition: transform .3s ease-out;
}


@media only screen and (max-width: 600px) {
  .hide-sm { display: none; }

  .modal {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1000px) {
  .hide-md-down { display: none; }
}

@media only screen and (min-width: 600px) {
  .hide-md-up { display: none; }
}

@media only screen and (min-width: 1000px) {
  .hide-lg { display: none; }
}
