.icon {
  padding-right: .7em;
}

.button {
  background: var(--text);
  color: var(--text-light);
  text-transform: uppercase;
  border: 0;
  outline: none;
  margin: 5px;
  font-size: .8em;
  font-weight: 700;
}

.button:not(.secondary):not(.tertiary) {
  letter-spacing: 1px;
  font-family: Ironstrike-BoldItalic;
}

.button.secondary {
  color: var(--text);
  background: none;
}

.button.tertiary {
  color: var(--secondary);
  background: none;
}

.button:not(.sm) {
  padding: 8px 15px;
}

.button.sm {
  margin: 2px 5px;
  padding: 0 15px;
}

.button:disabled {
  opacity: .5;
  cursor: default;
}

.button:hover:not(:disabled) {
  cursor: pointer;
}

.field-box {
  font-size: 12px;
}

.field-box:not(.inline) {
  border-bottom: 1px solid var(--secondary);
  padding: 15px 0 10px;
}

.field-box.inline {
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  margin-top: 5px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.field-box:last-of-type {
  border-bottom: 0;
}

.field-label:not(.inline) {
  margin-right: 5px;
  font-weight: 700;
}

.field-label.inline {
  width: 70px;
  margin: auto;
  font-weight: 500;
  display: inline-block;
}

.field-label:not(.inline) {
  margin: 0 3px .3em;
  display: block;
}

.field-input {
  background: var(--background-light);
  color: var(--text);
  transition: background var(--default-transition);
  border: none;
  outline: none;
  padding: .3em .45em;
  font-size: 1em;
}

.field-input:not(.inline) {
  width: 100%;
}

.field-input.inline {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 10px 0 0;
  font-weight: 700;
}

.field-input:disabled {
  background: var(--background);
  opacity: 1;
  -webkit-text-fill-color: var(--text);
}

.field-input[type="checkbox"] {
  zoom: 1.4;
  -webkit-flex: 0;
  -ms-flex: 0;
  flex: 0;
}

.field-input[type="date"], .field-input[type="time"], .field-input[type="datetime-local"], .field-input[type="month"], .field-input[type="select"] {
  padding: .19em .4em;
  font-family: Helvetica;
}

.field-input[type="select"] {
  padding: .2em .1em;
  font-family: Helvetica;
}

.field-input:required + .field-label:after {
  content: "*";
}

.field-option {
  color: var(--text);
}

select.field-input:disabled, .field-input[type="date"]:disabled, .field-input[type="datetime-local"]:disabled, .field-input[type="month"]:disabled, .field-input[type="time"]:disabled, .field-input[type="select"]:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.field-input[type="select"]:disabled {
  padding: .28em 1.59em .28em .44em;
}

.flex-col {
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flex-row {
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flex-row.spread {
  justify-content: space-between;
}

.flex-row.start {
  justify-content: flex-start;
}

.flex-row.center {
  justify-content: center;
}

div .language-select {
  font-size: 14px;
}

div .language-select > span {
  font-size: 36px;
}

.grid-row, .grid-head {
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.grid-head:hover {
  cursor: pointer;
}

.grid-cell {
  padding: 8px;
}

.grid-head {
  background: var(--primary);
  font-size: 1.2em;
}

.backdrop {
  transition: background var(--default-transition);
  will-change: background;
  z-index: 10;
  background: none;
  display: none;
  position: fixed;
  inset: 0;
}

.backdrop.active {
  display: initial;
  background: #00000059;
}

.modal {
  background: var(--background);
  margin: 10vh auto;
}

.swipepage-tabs {
  border-bottom: 1px solid var(--background-dark);
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-end;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.swipepage-tab {
  letter-spacing: .6px;
  text-align: center;
  text-transform: uppercase;
  -webkit-text-stroke: .8px var(--text);
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 15px 0 5px;
  font-size: 11px;
}

.swipepage-progressbar {
  position: relative;
}

.swipepage-tabs:not(.full-width), .swipepage-progressbar:not(.full-width) {
  margin: 0 20px;
}

.swipepage-progress {
  background: var(--primary-light);
  will-change: transform;
  height: 100%;
  position: absolute;
}

.swipepage-progress.transition {
  transition: -webkit-transform .3s ease-out, transform .3s ease-out;
}

@media only screen and (width <= 600px) {
  .hide-sm {
    display: none;
  }

  .modal {
    margin: 0 auto;
  }
}

@media only screen and (width <= 1000px) {
  .hide-md-down {
    display: none;
  }
}

@media only screen and (width >= 600px) {
  .hide-md-up {
    display: none;
  }
}

@media only screen and (width >= 1000px) {
  .hide-lg {
    display: none;
  }
}
/*# sourceMappingURL=index.3a0c9009.css.map */
